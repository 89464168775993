@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinnerWrapper {
  position: relative;

  &--small {
    width: 20px;
    height: 20px;
  }

  &--big {
    width: 82px;
    height: 82px;
  }
}

.spinner {
  position: absolute;
  top: 41%;
  left: 41%;
  border-radius: 50%;
  animation: spin 1.6s linear infinite;

  &--small {
    width: 4px;
    height: 4px;

    &--blue {
      box-shadow: 0 -0.45em rgba(1, 76, 255, 1),
        0.31em -0.31em rgba(1, 76, 255, 0.95), 0.45em 0 rgba(1, 76, 255, 0.9),
        0.31em 0.31em rgba(1, 76, 255, 0.85), 0 0.45em rgba(1, 76, 255, 0.8),
        -0.31em 0.31em rgba(1, 76, 255, 0.75), -0.45em 0 rgba(1, 76, 255, 0.7),
        -0.31em -0.31em rgba(1, 76, 255, 0.65);
    }

    &--gray {
      box-shadow: 0 -0.45em rgba(102, 99, 114, 1),
        0.31em -0.31em rgba(102, 99, 114, 0.95),
        0.45em 0 rgba(102, 99, 114, 0.9), 0.31em 0.31em rgba(102, 99, 114, 0.85),
        0 0.45em rgba(102, 99, 114, 0.8),
        -0.31em 0.31em rgba(102, 99, 114, 0.75),
        -0.45em 0 rgba(102, 99, 114, 0.7),
        -0.31em -0.31em rgba(102, 99, 114, 0.65);
    }

    &--white {
      box-shadow: 0 -0.45em rgba(255, 255, 255, 1),
        0.31em -0.31em rgba(255, 255, 255, 0.95),
        0.45em 0 rgba(255, 255, 255, 0.9),
        0.31em 0.31em rgba(255, 255, 255, 0.85),
        0 0.45em rgba(255, 255, 255, 0.8),
        -0.31em 0.31em rgba(255, 255, 255, 0.75),
        -0.45em 0 rgba(255, 255, 255, 0.7),
        -0.31em -0.31em rgba(255, 255, 255, 0.65);
    }
  }

  &--big {
    width: 16px;
    height: 16px;

    &--blue {
      box-shadow: 0 -0.45em rgba(1, 76, 255, 1),
        0.31em -0.31em rgba(1, 76, 255, 0.95), 0.45em 0 rgba(1, 76, 255, 0.9),
        0.31em 0.31em rgba(1, 76, 255, 0.85), 0 0.45em rgba(1, 76, 255, 0.8),
        -0.31em 0.31em rgba(1, 76, 255, 0.75), -0.45em 0 rgba(1, 76, 255, 0.7),
        -0.31em -0.31em rgba(1, 76, 255, 0.65);
    }

    &--gray {
      box-shadow: 0 1.68em rgba(102, 99, 114, 1),
        1.27em 1.17em rgba(102, 99, 114, 0.95),
        1.8em -0.1em rgba(102, 99, 114, 0.9),
        1.26em -1.38em rgba(102, 99, 114, 0.85),
        0.05em -1.88em rgba(102, 99, 114, 0.8),
        -1.28em -1.4em rgba(102, 99, 114, 0.75),
        -1.8em -0.1em rgba(102, 99, 114, 0.7),
        -1.25em 1.16em rgba(102, 99, 114, 0.65);
    }

    &--white {
      box-shadow: 0 -0.45em rgba(255, 255, 255, 1),
        0.31em -0.31em rgba(255, 255, 255, 0.95),
        0.45em 0 rgba(255, 255, 255, 0.9),
        0.31em 0.31em rgba(255, 255, 255, 0.85),
        0 0.45em rgba(255, 255, 255, 0.8),
        -0.31em 0.31em rgba(255, 255, 255, 0.75),
        -0.45em 0 rgba(255, 255, 255, 0.7),
        -0.31em -0.31em rgba(255, 255, 255, 0.65);
    }
  }
}
