@import 'assets/styles/abstracts/variables/media-queries';

.content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 24px;

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: $media-tablet-large) {
    row-gap: 28px;
    margin-bottom: 36px;
  }

  > div > label {
    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      display: block;
    }
  }

  &--response {
    align-items: center;
    margin: 68px 0 58px;

    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      margin: 44px 0 46px;
    }

    @media screen and (max-width: $media-tablet-large) {
      margin-bottom: 36px;
    }
  }
}
