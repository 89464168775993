@import 'assets/styles/abstracts/variables/colors.scss';
@import 'assets/styles/abstracts/variables/z-index.scss';

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zindex-filters-modal;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $color-brand-primary-900, $alpha: 0.2);
  z-index: $zindex-overlay-filters-modal;
}
