@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/functions';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;

  svg {
    @media screen and (max-width: calc($media-desktop-large - 1px)) {
      width: calc-fluid-element(
        142px,
        190px,
        $media-desktop-small,
        $media-desktop-large
      );
      height: calc-fluid-element(
        140px,
        188px,
        $media-desktop-small,
        $media-desktop-large
      );
    }

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      width: 142px;
      height: 140px;
    }
  }

  svg:nth-child(2) {
    position: absolute;
    right: -44px;
    bottom: -48px;

    @media screen and (max-width: calc($media-desktop-large - 1px)) {
      width: calc-fluid-element(
        70px,
        96px,
        $media-desktop-small,
        $media-desktop-large
      );
      height: calc-fluid-element(
        70px,
        96px,
        $media-desktop-small,
        $media-desktop-large
      );
      right: calc-fluid-element(
        -35px,
        -44px,
        $media-desktop-small,
        $media-desktop-large
      );
      bottom: calc-fluid-element(
        -35px,
        -48px,
        $media-desktop-small,
        $media-desktop-large
      );
    }

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      width: 70px;
      height: 70px;
      right: -35px;
      bottom: -35px;
    }
  }
}
