// SECTIONS
$indent-section-sides-desktop: 48px;
$indent-section-sides-tablet: 24px;
$indent-section-sides-mobile: 20px;

$indent-section-top-side-desktop-large: 80px;
$indent-section-top-side-desktop-medium: 64px;
$indent-section-top-side-desktop-small: 56px;
$indent-section-top-side-tablet: 40px;
$indent-section-top-side-tablet-medium: 32px;

$indent-main-top-screen-small: 115px;
$indent-search-top-tablet: 172px;
$indent-search-top-mobile: 142px;
$indent-main-top-screen-small-with-breadcrumbs: 78px;

// BLOCKS
$indent-block-default-top-side-large: 48px;
$indent-block-default-top-side-medium: 32px;
$indent-block-default-top-side-small: 24px;
