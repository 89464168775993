@import 'normalize.css/normalize.css';
@import 'assets/styles/abstracts/variables/fonts';

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', 'Arial', 'Helvetica', sans-serif;
  font-weight: 400;
  font-size: $font-size-article;
  line-height: $font-line-height-article;
  color: $color-neutral-900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: $transition-hover;
}

code {
  font-family: 'IBM Plex Sans', 'Arial', 'Helvetica', sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
a,
button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  user-select: none;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $color-brand-primary-100;
  border: 1px solid $color-neutral-0;
}

::-webkit-scrollbar-track {
  background: $color-neutral-0;
}

.page_no-scroll {
  overflow: hidden;
}
