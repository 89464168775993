@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/variables/wrappers';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/functions';

.userProfile {
  max-width: $wrapper-articles-preview-desktop;

  &_wrapper {
    max-width: $wrapper-profile-desktop;

    &_password {
      max-width: $wrapper-profile-password-desktop;

      > button {
        @extend %text-size-medium-semibold;

        > span {
          column-gap: 7px;
        }
      }

      @media screen and (max-width: calc($media-desktop-medium - 1px)) {
        max-width: 470px;
      }
    }

    @media screen and (max-width: $media-tablet-large) {
      max-width: 472px;
    }
  }

  &_general {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    @media screen and (max-width: $media-tablet-large) {
      row-gap: 24px;
    }

    > button {
      width: max-content;
    }
  }

  &_status {
    display: inline-flex;
    align-items: center;
    column-gap: 32px;
    @extend %text-size-large;

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      font-size: calc-fluid-element(
        calc($font-size-text-medium * 10),
        calc($font-size-text-large * 10),
        $media-tablet-small,
        $media-desktop-small
      );
    }

    @media screen and (max-width: $media-tablet-large) {
      letter-spacing: $font-letter-spacing-main;
    }

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      font-size: $font-size-text-medium;
    }

    &_heading {
      font-weight: 400;
      color: $color-neutral-500;
    }
  }

  &_role {
    display: inline-flex;
    align-items: center;
    column-gap: 12px;
  }

  &_title {
    @extend %heading-size-medium;
    margin-bottom: 32px;

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      font-size: calc-fluid-element(
        calc($font-size-heading-3 * 10),
        calc($font-size-heading-2 * 10),
        $media-tablet-small,
        $media-desktop-small
      );
    }

    @media screen and (max-width: $media-tablet-large) {
      margin-bottom: 28px;
      font-weight: 600;
      letter-spacing: $font-letter-spacing-main;
    }

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      font-size: $font-size-heading-3;
    }
  }

  &_subtitle {
    @extend %heading-size-small;
  }

  &_quote {
    @extend %text-size-small;
    color: $color-neutral-600;
  }

  &_box {
    margin-top: 38px;

    @media screen and (max-width: $media-tablet-large) {
      margin-top: 30px;
    }

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      margin-top: 32px;
    }
  }

  &_button_password {
    padding: 0;
  }

  &_doubleCheck {
    position: relative;
    display: inline-flex;

    > svg:last-child {
      position: absolute;
      left: 5px;
    }
  }
}

.email {
  color: $color-brand-primary-900;
  transition: $transition-button;
  transition-property: color;

  &:hover {
    color: $color-brand-primary-700;
  }

  &:active {
    color: $color-brand-primary-1000;
  }
}
