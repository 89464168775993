@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/z-index';
@import 'assets/styles/abstracts/variables/animations';

.label {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.input {
  position: absolute;
  z-index: $zindex-overlay-filters-modal;
  opacity: 0;

  &:checked + .checkmark {
    background-color: $color-brand-primary-900;

    &::after {
      content: url(../../../assets/icons/consent.svg);
    }
  }

  &:disabled + .checkmark {
    background-color: $color-neutral-100;
    border-color: $color-neutral-150;
    cursor: default;

    &:hover {
      border-width: 1px;
    }
  }
}

.error {
  & + .checkmark {
    border-color: $color-system-error;

    &:hover {
      border-color: $color-brand-primary-900;
    }
  }
}

.checkmark {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  border: 1px solid $color-brand-primary-900;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-width: 2px;
  }
}
