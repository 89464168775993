@import 'assets/styles/abstracts/variables/animations';

.overlayEnter {
  opacity: 0;
}

.overlayEnterActive {
  transition: opacity $transition-default-time ease-out;
  opacity: 1;
}

.overlayExit {
  opacity: 1;
}

.overlayExitActive {
  opacity: 0;
  transition: opacity $transition-default-time ease-out;
}
