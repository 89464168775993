@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/media-queries';

.button {
  width: 80px;
  height: 80px;

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    svg {
      stroke: inherit;
    }
  }

  &:active {
    svg {
      stroke: inherit;
    }
  }

  @media screen and (max-width: $media-tablet-large) {
    background-color: rgba($color: $color-brand-primary-100, $alpha: 0.7);
  }
}
