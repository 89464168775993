@import '../../assets/styles/index.scss';

.crumbs {
  max-width: $wrapper-news-preview-desktop;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;

  overflow: hidden;

  &__list {
    max-width: 100%;
    padding-top: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    column-gap: 4px;

    li:last-of-type {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > svg {
      @media screen and (max-width: $media-tablet-large) {
        min-width: 16px;
        height: 16px;
      }
    }
  }

  &__arrow {
    fill: $color-neutral-700;
    &:last-child {
      display: none;
    }
  }

  &__link {
    @extend %text-size-medium;
    color: $color-neutral-900;
    transition: all 100ms ease-out;

    &:hover {
      color: $color-brand-primary-900;
    }

    &:active {
      color: $color-brand-primary-1000;
    }

    @media screen and (max-width: $media-tablet-large) {
      font-size: $font-size-text-small;
      line-height: $font-line-height-text-small;
    }
  }
}
