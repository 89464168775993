@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/functions';

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_content {
    position: sticky;
    top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 56px); // height of button with indents
  }

  &_wrapper {
    @media screen and (max-width: $media-tablet-large) {
      display: flex;
      flex-direction: column;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    gap: 24px;
    padding-bottom: 56px;

    @media screen and (max-width: $media-tablet-large) {
      padding-bottom: 24px;
    }
  }

  &_info {
    max-width: 245px;
  }

  &_title {
    @extend %heading-size-small;
    letter-spacing: $font-letter-spacing-main;
    padding-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: $media-tablet-small) {
      font-size: $font-size-heading-3;
    }
  }

  &_subtitle {
    @extend %text-description;
    color: $color-neutral-500;
  }

  &_nav {
    @media screen and (max-width: $media-tablet-large) {
      order: 4;
    }
  }

  &_list {
    padding-bottom: 40px;

    @media screen and (max-width: $media-tablet-large) {
      display: flex;
      column-gap: 24px;
      padding-top: 30px;
      padding-bottom: 0;
    }

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      column-gap: 16px;
    }
  }

  &_list_item {
    @extend %heading-size-small;
    letter-spacing: $font-letter-spacing-main;
    padding-bottom: 16px;

    @media screen and (max-width: $media-tablet-large) {
      min-width: 100px;
      padding-bottom: 0;
      text-align: center;
      font-size: calc-fluid-element(
        calc($font-size-text-small-extra-extra * 10),
        calc($font-size-heading-3 * 10),
        $media-tablet-small,
        $media-desktop-small
      );
      font-weight: 500;
      letter-spacing: $font-letter-spacing-tablet;
    }

    @media screen and (max-width: $media-tablet-small) {
      font-size: $font-size-text-small-extra-extra;
    }
  }

  &_item {
    display: inline-block;
    box-sizing: border-box;
    color: $color-neutral-700;
    width: 100%;
    padding: 12px;

    @media screen and (max-width: $media-tablet-large) {
      padding-right: 7px;
      padding-left: 7px;
      border: 0.5px solid rgba($color: $color-neutral-350, $alpha: 0.36);
      border-radius: 12px;
      box-sizing: border-box;
    }

    &_active {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      color: $color-brand-primary-900;
      background-color: $color-brand-primary-100;
      border-radius: 12px;
      padding: 12px;

      @media screen and (max-width: $media-tablet-large) {
        padding-right: 0;
        padding-left: 0;
        border: none;
      }
    }
  }

  &_subscribe {
    order: 3;
  }

  &_email {
    @extend %text-article;
    color: $color-neutral-600;
    font-weight: 600;
    padding-bottom: 16px;
    letter-spacing: $font-letter-spacing-main;
  }

  &_mailingList {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &_label {
    color: $color-neutral-700;
    letter-spacing: $font-letter-spacing-main;
  }

  &_border {
    margin: 32px 0;
    border-top: 1px solid rgba(189, 189, 189, 0.36);

    @media screen and (max-width: $media-tablet-large) {
      margin-top: 0;
    }
  }

  &_logout {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    letter-spacing: $font-letter-spacing-main;
    color: $color-neutral-700;
    background-color: transparent;

    &:hover {
      color: $color-brand-primary-900;
    }

    &:active {
      color: $color-brand-primary-700;

      svg path {
        stroke: $color-brand-primary-700;
      }
    }

    * {
      transition: $transition-button;
    }

    @media screen and (max-width: $media-tablet-large) {
      display: none;
    }
  }
}
