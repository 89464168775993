@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/placeholders';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.wrapper > div > div {
  overflow: visible !important;
}

.carousel {
  width: calc(100% - 268px);
  padding-left: 50px;

  li:last-child .container_main {
    display: flex;
    justify-content: flex-start;
    gap: 14px;
  }

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    width: calc(100% - 241px);
  }

  @media screen and (max-width: $media-tablet-large) {
    padding-left: 0;
    width: 100%;
  }
}

.next_button {
  position: absolute;
  right: -64px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;

  svg {
    fill: $color-brand-primary-900;
  }

  &_disabled {
    svg {
      fill: $color-neutral-400;
    }
  }

  @media screen and (max-width: $media-tablet-large) {
    display: none;
  }
}

.prev_button {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;

  svg {
    fill: $color-brand-primary-900;
  }

  &_disabled {
    svg {
      fill: $color-neutral-400;
    }
  }

  @media screen and (max-width: $media-tablet-large) {
    display: none;
  }
}

.container_main {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: calc($media-desktop-small + 1px)) {
  .container_main {
    justify-content: space-between;
  }
}

.container_articles {
  display: flex;
  width: 1350px;
  gap: 5px;
}

.button_filter_wrapper {
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: $media-tablet-large) {
    position: absolute;
    top: -66px;
    right: 0;
  }
}

.counter {
  @extend %text-size-small-extra;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $color-brand-secondary-700;
  color: $color-neutral-0;
  position: absolute;
  top: 20px;
  right: calc(100% - 28px);

  @media screen and (max-width: $media-tablet-large) {
    top: 4px;
  }
}
