@import '../../assets/styles/index.scss';

.user {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  padding: 0;

  &__default {
    background: none;
    fill: $color-neutral-0;
    transition: $transition-button;

    &:hover {
      fill: $color-brand-primary-500;
      svg {
        fill: $color-brand-primary-500;
      }
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    @extend %text-size-medium-semibold;
    color: $color-brand-primary-900;

    border-radius: 50px;
    border: 1px solid $color-brand-primary-500;
    background-color: $color-neutral-0;

    &-header {
      @media screen and (max-width: calc($media-desktop-medium - 1px)) {
        width: 40px;
        height: 40px;
      }

      @media screen and (max-width: $media-tablet-large) {
        width: 24px;
        height: 24px;
      }

      svg {
        fill: $color-brand-secondary-900;
      }

      &:hover {
        color: $color-brand-primary-500;
      }
    }

    &-image {
      &:hover {
        > .user__plus {
          fill: $color-brand-secondary-700;
        }
      }
    }

    &-mask {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 140px;
        background-color: $color-neutral-900;
        opacity: 0;
        transition: $transition-button;
      }

      &:hover::before {
        opacity: 0.3;
      }
    }

    * {
      transition: $transition-button;
    }
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
  }

  &__content {
    text-align: center;
    vertical-align: middle;
    user-select: none;

    @media screen and (max-width: $media-tablet-large) {
      font-size: $font-size-text-small-extra;
      line-height: $font-line-height-text-small-extra;
      font-weight: 700;
    }
  }

  &__plus {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    right: 0;

    &-header {
      @media screen and (max-width: $media-tablet-large) {
        width: 12px;
        height: 12px;
      }
    }
  }

  &:hover {
    transition: $transition-button;
    cursor: pointer;
  }
}
