@import 'assets/styles/abstracts/variables/animations';

.avatarModalEnter {
  opacity: 0;
}

.avatarModalEnterActive {
  opacity: 1;
  animation: moveUp $transition-default-time ease-out;
}

.avatarModalExit {
  opacity: 1;
}

.avatarModalExitActive {
  opacity: 0;
  animation: moveDown $transition-default-time ease-out;
}

@keyframes moveUp {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(2rem);
  }
}
