@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts.scss';
@import 'assets/styles/abstracts/variables/media-queries.scss';

.billet {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: $color-neutral-0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 47px;
  max-width: 335px;
  margin: 0 auto;
  padding: 8px 0 60px;
}

.handle {
  display: inline-block;
  width: 60px;
  height: 5px;
  border-radius: 100px;
  background-color: $color-neutral-400;

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    width: 38px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  font-size: $font-size-text-medium-small;
  line-height: $font-line-height-text-medium-small;

  &_unauth {
    padding-right: 83px;
    padding-left: 83px;
    font-size: inherit;
    line-height: inherit;
  }

  &_auth {
    height: 48px;
    padding-right: 56px;
    padding-left: 56px;
    font-size: inherit;
    line-height: inherit;
  }
}
