@import 'assets/styles/abstracts/variables/colors.scss';
@import 'assets/styles/abstracts/variables/fonts.scss';
@import 'assets/styles/abstracts/variables/media-queries.scss';
@import 'assets/styles/abstracts/variables/z-index';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders.scss';
@import 'assets/styles/abstracts/functions.scss';

.fileWrapper {
  position: relative;
  width: max-content;
  padding: 12px 0;
}

.file {
  position: absolute;
  z-index: $zindex-overlay-filters-modal;
  width: 0;
  height: 0;
  opacity: 0;
}

.fileLabel {
  display: flex;
  align-items: center;
  column-gap: 4px;
  @extend %text-size-medium-semibold;
  letter-spacing: $font-letter-spacing-main;
  color: $color-brand-primary-900;
  cursor: pointer;

  &:hover {
    color: $color-brand-primary-700;

    svg {
      path {
        fill: $color-brand-primary-700;
      }
    }
  }

  &:active {
    color: $color-brand-primary-1000;

    svg {
      path {
        fill: $color-brand-primary-1000;
      }
    }
  }

  * {
    transition: $transition-button;
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    font-size: calc-fluid-element(
      calc($font-size-text-medium-small * 10),
      calc($font-size-text-medium * 10),
      $media-tablet-small,
      $media-desktop-small
    );
  }

  @media screen and (max-width: $media-tablet-large) {
    font-size: $font-size-text-medium-small;
  }
}
