@import '../../../assets/styles/index.scss';

@keyframes comments-part {
  from {
    right: 0;
  }

  to {
    right: 115px;
  }
}

.comment {
  width: 100%;
  min-height: 140px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  transition: $transition-hover;

  &--content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    row-gap: 20px;
    border: 1px solid #bdbdbd5c;
    border-radius: 16px;
    transition: right 100ms ease-out;

    &-owner {
      background-color: $color-neutral-100;
    }

    &-open {
      padding-right: 0;
      position: absolute;
      right: 115px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      animation: comments-part 100ms ease-out;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--name {
    @extend %text-size-medium-semibold;
    color: $color-neutral-900;

    &-owner {
      color: $color-brand-primary-900;
    }
  }

  &__date {
    @extend %text-size-small;
    color: $color-neutral-500;

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      font-size: $font-size-text-small-extra;
    }
  }

  &__user {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__text {
    max-width: 722px;
    color: $color-neutral-900;
    @extend %text-article;

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      font-size: $font-size-text-medium;
    }
  }

  &__remove {
    color: $color-neutral-500;
    @extend %text-size-small-extra;

    :hover {
      color: red;
    }
  }

  &--removeMobileCont {
    height: 142px;
    display: flex;
    align-items: center;
    background-color: $color-system-error;
    width: 0;
    transition: $transition-hover;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;

    &-open {
      width: 67px;
      padding: 0 24px;
    }
  }

  &__removeMobile {
    color: #fff;
    font-size: $font-size-text-medium-small;
  }
}
