@import '../../assets/styles/index.scss';

.comments {
  max-width: 900px;
  margin: 50px 0 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $media-tablet-large) {
    margin-top: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 18px;

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      margin-top: 10px;
    }
  }

  &__show {
    align-self: flex-start;

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      font-size: $font-size-text-medium;
    }

    @media screen and (max-width: $media-tablet-large) {
      align-self: center;
    }
  }

  &__label {
    color: $color-neutral-900;
    @extend %text-size-medium-semibold;
    margin-bottom: 18px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 18px 0;

    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      padding: calc($indent-block-default-top-side-small / 2) 0;
    }

    @media screen and (max-width: $media-tablet-large) {
      padding: 0;
      margin: 14px 0;
    }
  }

  &__send {
    height: 48px;
    width: 225px;
    padding: 0;
    align-self: flex-end;
    font-size: $font-size-text-medium;

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      width: 100%;
    }
  }

  &__auth {
    margin: 23px 0;

    @media screen and (max-width: $media-tablet-large) {
      margin: 7px 0;
    }

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      font-size: $font-size-text-medium-small;
      letter-spacing: $font-letter-spacing-main;
    }
  }
}
