@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/functions';

.userProfile {
  &_form {
    margin-top: 17px;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid rgba(189, 189, 189, 0.36);

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      margin-top: 14px;
      padding: 0;
      border: none;
    }
  }

  &_title {
    @extend %heading-size-small;
  }

  &_inputs {
    margin: 32px 0 28px;

    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      margin: 26px 0 20px;
    }

    > div {
      &:not(:first-child) {
        margin-top: 15px;

        @media screen and (max-width: calc($media-desktop-medium - 1px)) {
          margin-top: 8px;
        }
      }
    }

    @media screen and (max-width: $media-tablet-large) {
      margin-bottom: 22px;
    }

    @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
      margin-top: 18px;
      margin-bottom: 18px;
    }
  }

  &_button {
    width: 100%;
  }

  &_nav {
    padding: 0;
  }
}
