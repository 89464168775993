@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/functions';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.wrapper_signup {
  composes: wrapper;
  align-items: flex-end;

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    align-items: center;
  }
}

.logo {
  max-width: 284px;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    max-width: calc-fluid-element(
      204,
      284,
      $media-desktop-small,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    max-width: calc-fluid-element(
      153,
      204,
      $media-tablet-small,
      $media-desktop-small
    );
  }

  @media screen and (max-width: calc($media-tablet-small - 1px)) {
    max-width: 153px;
  }
}

.logo_signup {
  max-width: 592px;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    max-width: calc-fluid-element(
      382,
      592,
      $media-desktop-small,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    max-width: 382px;
  }
}

.caption {
  @extend %text-size-small;
  color: $color-neutral-0;
  font-weight: 600;
  margin-top: 4px;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    font-size: calc-fluid-element(
      calc(1rem * 10),
      calc(1.4rem * 10),
      $media-desktop-medium,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    font-size: 1rem;
    font-weight: 500;
  }

  @media screen and (max-width: $media-tablet-large) {
    display: none;
  }

  &Dark {
    color: $color-neutral-900;
  }
}

.caption_signup {
  @extend %text-size-large;
  color: $color-neutral-900;
}
