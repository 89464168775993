@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/functions';

.icon_button {
  background-color: $color-neutral-0;
  outline: 0;
  width: 24px;
  height: 24px;

  &:disabled {
    background-color: $color-neutral-100;
    cursor: auto;
  }
}

.input {
  max-width: 648px;

  &--label {
    @extend %text-size-medium;
    color: $color-neutral-700;

    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      font-size: $font-size-text-small;
      line-height: $font-line-height-text-small;
    }

    &--disabled {
      color: $color-neutral-400;
    }
  }

  &--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0 4px;
    padding: 19px 12px;
    border: 1px solid $color-neutral-400;
    border-radius: 16px;
    transition: $transition-button;

    &--medium {
      padding: 19px 12px;

      @media screen and (max-width: calc($media-desktop-medium - 1px)) {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }

    &--small {
      padding: 11px;
    }

    &:hover {
      border: 1px solid $color-brand-primary-900;
      cursor: text;
    }

    &:focus-within {
      border: 1px solid $color-brand-primary-900;
    }

    &--success {
      border: 1px solid $color-brand-primary-500;
    }

    &--error {
      border: 1px solid $color-system-error;

      &:hover {
        border-color: $color-system-error;
      }

      &:focus-within {
        border-color: $color-system-error;
      }
    }

    &--disabled {
      border: 1px solid $color-neutral-100;
      background-color: $color-neutral-100;

      &:hover {
        border: 1px solid $color-neutral-100;
      }
    }

    &--input {
      width: 100%;
      @extend %text-size-medium;
      padding: 0;
      padding-right: 8px;
      border: none;
      color: $color-neutral-900;
      caret-color: $color-brand-primary-900;

      &::placeholder {
        color: $color-neutral-400;
      }

      &:focus-visible {
        outline: none;
      }

      &--error {
        color: $color-system-error;
        caret-color: $color-system-error;
      }

      &:disabled {
        background-color: $color-neutral-100;
        color: $color-neutral-400;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &--span {
    @extend %text-size-small-light;
    display: block;
    min-height: 16px;
    color: $color-system-error;

    @media screen and (max-width: calc($media-desktop-large - 1px)) {
      font-size: calc-fluid-element(
        calc($font-size-text-small-extra * 10),
        calc($font-size-text-small * 10),
        $media-desktop-small,
        $media-desktop-large
      );
    }

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      font-size: $font-size-text-small-extra;
      line-height: $font-line-height-text-small-extra;
    }

    @media screen and (max-width: $media-tablet-large) {
      min-height: 0;
    }
  }
}
