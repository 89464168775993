@import 'assets/styles/abstracts/variables/animations';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 123px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: $transition-button;
}

.active {
  height: 100vh;
  padding-top: 115px;
  opacity: 1;
  visibility: visible;
}

.search {
  max-width: 716px;
  width: 100%;
}

.menu {
  > ul {
    flex-direction: column;
    row-gap: 60px;
  }
}
