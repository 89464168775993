// DESKTOP
$wrapper-main-content-desktop: 1824px;
$wrapper-articles-preview-desktop: 1516px;
$wrapper-news-preview-desktop: 1208px;
$wrapper-authors-desktop: 1054px;
$wrapper-entry-form-desktop: 640px;
$wrapper-response-desktop: 592px;
$wrapper-profile-password-desktop: 502px;
$wrapper-profile-desktop: 438px;
$wrapper-search-preview-desktop: 1208px;
$wrapper-search-desktop: 1054px;
$wrapper-card-article-preview-desktop: 830px;
