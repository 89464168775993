@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/functions';

.content,
.iconWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  row-gap: 12px;
  margin-bottom: 24px;

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    row-gap: 6px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: $media-tablet-large) {
    row-gap: 12px;
    margin-bottom: 42px;
  }
}

.instruction {
  @extend %text-size-large;
  color: $color-neutral-500;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    font-size: calc-fluid-element(
      calc($font-size-text-medium * 10),
      calc($font-size-text-large * 10),
      $media-desktop-small,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    line-height: $font-line-height-text-medium;
    letter-spacing: $font-letter-spacing-main;
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    font-size: $font-size-text-medium;
  }

  &_success {
    @media screen and (max-width: $media-tablet-large) {
      text-align: center;
    }
  }
}

.iconWrapper {
  align-items: center;
  margin: 28px 0 16px;
  min-height: 235px;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    min-height: calc-fluid-element(
      172px,
      235px,
      $media-desktop-small,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    margin: 20px 0 0;
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    min-height: 172px;
  }

  @media screen and (max-width: $media-tablet-large) {
    margin: 46px 0 60px;
  }

  div {
    margin-right: 8%;

    @media screen and (max-width: calc($media-desktop-medium - 1px)) {
      margin-right: 0;
    }
  }
}

.buttonBack {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    margin-top: 26px;
  }
}
