@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/variables/wrappers';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/functions';

.article {
  max-width: $wrapper-search-desktop;

  a {
    color: initial;
  }

  &:hover a > .wrapper .heading {
    color: $color-brand-secondary-900;
  }

  * {
    transition: $transition-button;
  }
}

.wrapper {
  display: flex;
  column-gap: 24px;
  height: 148px;

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    flex-direction: column;
    height: auto;
    row-gap: 16px;
  }
}

.image {
  max-width: 200px;
  min-width: 200px;
  object-fit: cover;
  border-radius: 12px;

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    max-width: inherit;
    height: calc-fluid-element(
      148px,
      273px,
      $media-mobile-small,
      $media-tablet-small-extra
    );
  }
}

.heading {
  display: -webkit-box;
  max-height: 64px;
  @extend %heading-size-small;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    font-size: calc-fluid-element(
      calc($font-size-text-medium * 10),
      calc($font-size-heading-3 * 10),
      $media-tablet-small,
      $media-desktop-small
    );
  }

  @media screen and (max-width: $media-tablet-large) {
    line-height: $font-line-height-text-medium;
  }

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    font-size: $font-size-text-medium-small;
    line-height: $font-line-height-text-medium-small;
  }
}

.text {
  display: -webkit-box; // TODO: Если последнее слово в параграфе имеет точку, то получается многоточие из 4 точек. Также замечен баг, когда многоточие начинается после пустого пробела
  margin-top: 16px;
  @extend %text-description;
  color: $color-neutral-700;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    font-size: $font-size-text-medium-small;
    line-height: $font-line-height-text-medium-small;
    margin-top: 12px;
  }
}
