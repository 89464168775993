@import 'assets/styles/abstracts/variables/animations';

.contentEnter {
  opacity: 0;
}

.contentEnterActive {
  opacity: 1;
  animation: moveUp $transition-default-time ease-out;
}

.contentExit {
  opacity: 1;
}

.contentExitActive {
  opacity: 0;
  animation: moveDown $transition-default-time ease-out;
}

@keyframes moveUp {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(2rem);
  }
}
