@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/variables/media-queries.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 128px;
  background-color: $color-neutral-0;
  border-radius: 24px;

  @media screen and (max-width: $media-tablet-large) {
    position: fixed;
    width: 100%;
    height: 100%;
    margin-top: 240px;
    padding: 32px 0 0;
  }
}

.heading {
  @extend %heading-size-small;

  @media screen and (max-width: $media-tablet-large) {
    font-size: $font-size-article;
    line-height: $font-line-height-article;
  }
}

.placemat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 224px;
  margin: 40px 0 20px;
  text-align: center;
  border-radius: 140px;
  background-color: $color-brand-primary-100;

  @media screen and (max-width: $media-tablet-large) {
    margin: 270px 0 80px;
  }

  @media screen and (max-width: calc($media-tablet-small-extra - 1px)) {
    margin-top: 75px;
  }

  &--error {
    border: 2px dashed $color-system-error;
    box-sizing: border-box;
    color: $color-system-error;
    background-color: transparent;

    .rule {
      width: 91%;
    }
  }
}

.button_text {
  > label {
    font-size: $font-size-text-medium;
  }
}

.action {
  display: flex;
  align-items: center;
  column-gap: 56px;

  > button {
    height: max-content;
    padding: 0;
    color: $color-system-error;
    font-size: $font-size-text-medium;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 140px;
}

.rule {
  width: 84.82%;
  @extend %text-size-small-extra;
  white-space: pre-wrap;
  user-select: none;
}

.closeButton {
  position: absolute;
  top: 27px;
  right: 28px;
  width: 24px;
  height: 24px;

  @media screen and (max-width: $media-tablet-large) {
    top: 32px;
    right: 32px;
  }

  svg {
    fill: $color-brand-primary-900;
  }
}
