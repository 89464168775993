@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/animations';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/functions';

.checkbox:not(:checked),
.checkbox:checked {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox {
  + .label {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    height: 68px;
    padding: 7px 12px;
    box-sizing: border-box;
    white-space: nowrap;

    @extend %text-size-medium;
    color: $color-neutral-500;

    background-color: $color-neutral-0;

    user-select: none;
    cursor: pointer;

    transition: $transition-button;
    transition-property: background-color, color, stroke;

    &:hover {
      color: $color-brand-primary-900;
      background-color: $color-brand-primary-100;

      svg path {
        stroke: $color-brand-primary-900;
        fill: $color-brand-primary-900;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    @media screen and (max-width: calc($media-desktop-small - 1px)) {
      font-size: calc-fluid-element(
        calc($font-size-text-small-extra * 10),
        calc($font-size-text-medium * 10),
        $media-tablet-small,
        $media-desktop-small
      );
      height: calc-fluid-element(
        60px,
        68px,
        $media-tablet-small,
        $media-desktop-small
      );
    }

    @media screen and (max-width: $media-tablet-large) {
      line-height: $font-line-height-text-small-extra;
    }

    @media screen and (max-width: calc($media-tablet-small - 1px)) {
      height: 60px;
      font-size: $font-size-text-small-extra;
    }
  }

  &:checked {
    + .label {
      color: $color-brand-primary-900;

      background-color: $color-neutral-0;
      border-bottom: 2px solid $color-brand-primary-900;

      svg path {
        stroke: $color-brand-primary-900;
        fill: $color-brand-primary-900;
      }
    }
  }
}
