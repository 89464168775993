@import 'assets/styles/abstracts/variables/colors';
@import 'assets/styles/abstracts/variables/fonts';
@import 'assets/styles/abstracts/variables/media-queries';
@import 'assets/styles/abstracts/variables/wrappers.scss';
@import 'assets/styles/abstracts/variables/indents';
@import 'assets/styles/abstracts/placeholders';
@import 'assets/styles/abstracts/functions';

$indent-tablet: calc-fluid-element(
  $indent-section-sides-tablet,
  $indent-section-sides-desktop,
  $media-tablet-small,
  $media-desktop-small
);
$indent-mobile: calc-fluid-element(
  $indent-section-sides-mobile,
  $indent-section-sides-tablet,
  $media-mobile-small,
  $media-tablet-small
);

.wrapper {
  display: grid;
  grid-template-columns: 360px auto;
  column-gap: 5.59%;
  max-width: $wrapper-main-content-desktop;
  margin: 0 auto;
  padding: 48px 0 80px;

  @media screen and (max-width: calc($media-desktop-large - 1px)) {
    margin: 0 $indent-section-sides-desktop;
    column-gap: calc-fluid-element(
      43px,
      102px,
      $media-desktop-small,
      $media-desktop-large
    );
  }

  @media screen and (max-width: calc($media-desktop-medium - 1px)) {
    padding-top: 52px;
  }

  @media screen and (max-width: calc($media-desktop-small - 1px)) {
    margin: 0 $indent-tablet;
  }

  @media screen and (max-width: $media-tablet-large) {
    grid-template-columns: 1fr;
    row-gap: 35px;
    padding: 104px 0 32px;

    > button {
      &:last-child {
        position: fixed;
        bottom: 36px;
        right: $indent-tablet;
      }
    }
  }

  @media screen and (max-width: calc($media-tablet-small - 1px)) {
    margin: 0 $indent-mobile;

    > button {
      &:last-child {
        right: $indent-mobile;
      }
    }
  }

  &--publication {
    padding-bottom: 80px;

    @media screen and (max-width: $media-tablet-large) {
      padding-bottom: 32px;
    }
  }
}
