$color-neutral-900: #1f1d23;
$color-neutral-700: #484554;
$color-neutral-600: #666372;
$color-neutral-500: #838090;
$color-neutral-400: #ada9bb;
$color-neutral-350: #bdbdbd;
$color-neutral-300: #bbb;
$color-neutral-150: #ececec;
$color-neutral-100: #f4f4f4;
$color-neutral-0: #fff;

$color-brand-primary-1000: #002de0;
$color-brand-primary-900: #014cff;
$color-brand-primary-700: #387cff;
$color-brand-primary-600: #4f8bff;
$color-brand-primary-500: #7ba8ff;
$color-brand-primary-100: #e1eaff;

$color-brand-secondary-900: #ff0041;
$color-brand-secondary-700: #ff275e;

$color-system-success: #34c759;
$color-system-error: #ff3b30;
