@import 'assets/styles/abstracts/variables/colors.scss';

.tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  max-width: 395px;
  padding: 40px 0;
  border-radius: 16px;
  background: $color-neutral-100;
}

.button {
  padding: 12px 130px;
}

.tip {
  position: absolute;
  top: -8px;
  right: 14px;
}
